.root {
    display: flex;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    gap: 8px;
    color: var(--bcn-color-white-001);
}

.lan {
    cursor: pointer;
}

.choose {
    cursor: unset;
    font-weight: 700;
}